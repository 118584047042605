@import '../../scss/imports';

.graph-bar-chart-cell {
  border-style: solid;
  border-width: 2px;
  display: inline-flex;
  height: 1.25rem;
  width: 100%;

  &.graph-bar-chart-cell-group {
    display: inline-flex;
  }
}
