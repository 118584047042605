@import '../../scss/imports';

#expanded-vips-view-modal {
  .modal-body {
    overflow-y: hidden;
  }

  .vips-table {
    font-family: $font-demi;
    font-size: 1rem;
    min-width: 100%;
    padding: 0 1.5rem 4rem;
  }

  .vips-table-header {
    font-weight: bold;
    margin-right: 0;
    text-transform: none;

    &:first-of-type {
      margin-left: 0;
      padding-left: 1.5rem;
    }
  }

  .header-label {
    align-items: center;
    display: flex;

    svg {
      padding-left: .375rem;
    }
  }
}
