@use '../../scss/variables' as *;

.map-container {
  height: calc(100vh - 4.25rem);
  width: 100vw;
}

.popup-title {
  font-family: $font-demi;
  font-size: 1rem;
  margin: 0;
}

.popup {
  .mapboxgl-popup-content {
    padding: 1.25rem;
  }
}
