@import '../../scss/imports';

.modal-body {
  .cashiers-table {
    th {
      &:nth-child(2) {
        width: 5.625rem;
      }

      &:nth-child(3) {
        width: 2rem;
      }
    }

    td {
      &:nth-child(2) {
        padding-left: 2.375rem;
      }

      &:nth-child(3) {
        button {
          color: #c7c7c7;
        }
      }
    }
  }

  .reprints-table {
    th,
    td {
      &:nth-child(2) {
        max-width: 5.5rem;
      }

      &:nth-child(4) {
        max-width: 7.5rem;
      }

      &:nth-child(5) {
        max-width: 7.5rem;
      }
    }
  }
}

.cashier-reprints-no-data {
  font-size: 1.25rem;
  margin: 0;
  padding: 3rem 0;
  text-align: center;
}

#expanded-reprints-modal {
  .modal-content {
    max-width: 60rem;
  }
}
