@import '../../scss/imports';

.cash-management-no-data {
  font-size: 1.25rem;
  margin: 0;
  padding: 3rem 0;
  text-align: center;
}

.cash-management-dropdown {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding-bottom: 7rem;

  button {
    grid-column: 2;
  }
}

.cash-management-action {
  margin: 0;
}

.cash-management-details {
  grid-column: 1 / -1;
  grid-row: 2;
  line-height: 1.5;
  list-style-type: none;
  padding: 0 1rem;
}

.cash-management-item {
  background-color: var(--white);
  border-radius: .5rem;
  box-shadow: 0 .375rem 1.625rem 0 rgba(130, 141, 168, .2);
  display: grid;
  font-size: 1.25rem;
  grid-column-gap: 1.25rem;
  grid-row-gap: .5rem;
  grid-template-columns: 1fr auto;
  margin-bottom: .75rem;
  padding: 1rem;

  .button-blue-light {
    grid-column: 1 / -1;
    justify-self: center;
  }
}

.cash-management-list {
  list-style-type: none;
  margin: 0;
  padding: 2rem 0;
}

.cash-management-modal-body {
  padding: 0 1.25rem;
}

.cash-management-time {
  color: var(--gray-500);
  font-family: var(--font-demi);
  grid-column: 2;
  grid-row: 1;
}

#cashout-tooltip,
#recount-tooltip {
  z-index: 202;
}

#cash-management-dropdown-button-menu {
  z-index: 201;
}

@media (max-width: $sm) {
  .cash-management-modal-body {
    padding: 0 .75rem;
  }
}
