@mixin transition() {
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}

@mixin loading-styles() {
  animation: 1.5s shine linear infinite;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
}
