@import '../../scss/imports';

.graph-bar {
  display: grid;

  @media (max-width: $sm) {
    grid-template-columns: auto 1.5rem;
  }

  button {
    background: #f3f1f1;
    border-radius: 100%;

    i {
      margin: 0;
      min-width: 1.75rem;
    }
  }

  .rmd-button--text,
  .rmd-ripple-container {
    border-radius: 100%;
    margin: 0;
    min-height: 1.75rem;
    min-width: 1.5rem;
    padding: 0;

    @media (min-width: $sm) {
      display: none;
    }
  }

  .rmd-icon-rotator--animate {
    transition: none;
  }

  .graph-bar-chart {
    border: 2px solid #efefef;
    border-radius: .25rem;
    display: flex;
    flex-direction: row;
    gap: .125rem;
    height: 1.75rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: .125rem;

    @media (max-width: $sm) {
      margin: 0 .5rem .75rem 0;
    }

    .graph-bar-chart-cell-container {
      border-radius: .25rem;
      display: inline-flex;
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      height: 1.25rem;
      min-width: 1%;
      overflow: hidden;
    }
  }
}
