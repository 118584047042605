@import 'variables';
@import '~@react-md/theme/dist/mixins';
@import '~@react-md/button/dist/mixins';

.tertiary-button {
  @include rmd-theme-update-var(primary, $tertiary-color);
  @include rmd-theme-update-var(on-primary, $tertiary-text-color);
}

.warning-button {
  @include rmd-theme-update-var(primary, $warning-color);
  @include rmd-theme-update-var(on-primary, $warning-text-color);
}

.no-elevate {
  box-shadow: none;
}

.button-blue-light {
  --rmd-button-color: $blue;
  @include rmd-button-theme-update-var(background-color, $blue-light);
  color: $blue;
}

.icon-button {
  @include rmd-button-theme-update-var(icon-size, 2rem);
}
