@import '../../scss/imports';

.chip-loading {
  @include loading-styles();
  border-radius: 1.625rem;
  height: 2rem;
  margin: 0 .25rem;
  position: relative;
  text-align: start;
  width: 6.625rem;

  &:first-child {
    margin-left: 0;
  }
}
