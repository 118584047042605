@import '../../scss/imports';

.empty-chart-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.center {
    flex-grow: 1;
  }

  .empty-chart-text {
    box-sizing: border-box;
    color: #bbb;
    font-size: 1rem;
    margin: .625rem 0;
    text-align: center;
  }
}
