@import '../../scss/imports';

.chart-legend {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .legend-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    + .legend-item {
      margin-top: .375rem;
    }
  }

  .legend-color {
    border-radius: .1875rem;
    height: 1rem;
    margin-right: .375rem;
    width: 1rem;
  }

  .legend-name {
    font-size: 1.125rem;
  }

  .legend-label {
    align-items: center;
    display: inline-flex;
  }

  .legend-value {
    font-family: $font-demi;
    font-size: 1.125rem;
    line-height: 1.25rem;
    text-align: right;
  }
}
