@use 'variables' as theme;
@use './everything' as *;
@include react-md-utils;

.rmd-button {
  font-family: theme.$font-demi;
}

.rmd-button--primary {
  @include rmd-theme-update-var(on-primary, theme.$white);
}

.ph-checkbox {
  @include rmd-button-theme-update-var(icon-size, 1.5rem);
  @include rmd-icon-theme-update-var(color, theme.$primary-color);
  @include rmd-theme-update-var(secondary, theme.$primary-color);
  @include rmd-theme-update-var(text-secondary-on-background, theme.$primary-color);
  height: 2.5rem;

  label {
    color: #000;
    cursor: pointer;
    flex-grow: 1;
    font-size: .875rem;
  }

  &.top-control {
    label {
      font-family: theme.$font-demi;
    }
  }

  &.secondary {
    @include rmd-icon-theme-update-var(color, theme.$secondary-color);
    @include rmd-theme-update-var(secondary, theme.$secondary-color);
    @include rmd-theme-update-var(text-secondary-on-background, #646464);
  }
}

.rmd-toggle-container {
  .rmd-label {
    cursor: pointer;
  }
}

.rmd-toggle__input:checked + .rmd-toggle__icon {
  color: theme.$primary-color;
}
