@import '../../scss/imports';

#on-site-revenue-graph-bar {
  border: .125rem solid rgba(239, 239, 239, 1);
  border-radius: .25rem;
  display: flex;
  flex-direction: row;
  gap: .125rem;
  height: 1.75rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: .125rem;

  .cell-type-container {
    border-radius: .25rem;
    display: inline-flex;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    height: 1.25rem;
    min-width: 1%;
    overflow: hidden;

    .revenue-cell {
      border-style: solid;
      border-width: 2px;
      display: inline-flex;
      height: 1.25rem;
      width: 100%;

      &.payment-cell {
        border-left-width: 0;
      }
    }
  }
}
