@import '../../scss/imports';

.donut-title {
  font-size: 2.25rem;
}

.donut-subtitle {
  color: rgba(0, 0, 0, .55);
  font-size: 1.0625rem;
}
