@import '../../scss/imports';

.lots-view-container {
  .top-action-bar {
    background: transparent;
    display: flex;
    justify-content: space-between;
  }

  &.grid-view {
    .search-input {
      margin-top: 1.75rem;
    }

    .toggle-grid {
      align-items: center;
      display: flex;
      margin: 1.875rem 2.5rem .375rem 0;
    }

    .lot-cards-container {
      flex-wrap: wrap;
      max-width: 100vw;
      padding: 0 1.5rem;

      .lot-card-container {
        min-width: 16rem;
      }
    }
  }

  &.map-view {
    .top-action-bar {
      position: absolute;
      width: 100%;
      z-index: 20;

      .sort-container {
        align-items: center;
        background: $white;
        border-radius: .3125rem;
        box-shadow: 0 .125rem .5rem rgba(0, 0, 0, .1);
        display: flex;
        height: 1.6875rem;
        margin: 2.375rem 6.625rem;
        padding: .5rem .25rem .5rem .75rem;

        p {
          margin-right: .25rem;
        }

        .expand {
          padding-top: .1875rem;
        }
      }

      .toggle-grid {
        align-items: center;
        background: $white;
        border-radius: .3125rem;
        box-shadow: 0 .125rem .5rem rgba(0, 0, 0, .1);
        display: flex;
        height: 1.6875rem;
        margin: 2.375rem 2.5rem;
        padding-left: .5rem;

        button {
          padding-left: .5rem;
        }
      }
    }

    .lot-cards-container {
      align-items: flex-end;
      direction: rtl;
      flex-direction: column;
      max-height: calc(100vh - 9rem);
      overflow: scroll;
      overflow-x: hidden;
      position: absolute;
      top: 9rem;
      z-index: 20;

      .lot-card-container {
        border: 1px solid rgba(0, 0, 0, .1);
        cursor: pointer;
        direction: ltr;
        margin: 8px 1rem;
        min-width: 18rem;
        padding-bottom: .125rem;
        transform: scale(1);
        transition: 100ms ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .lot-cards-container {
    display: flex;

    .lot-card-container {
      background: $white;
      border-radius: .3125rem;
      box-shadow: 0 .125rem .5rem rgba(0, 0, 0, .2);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: .5rem;
      width: 17.55rem;

      .lot-card-title {
        display: flex;
        font-size: 1.125rem;
        justify-content: space-between;
        padding: .625rem .625rem 0;
        width: 100%;

        p {
          font-family: $font-demi;
          margin: 0;

          &:first-of-type {
            max-width: 7.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .graphic-container {
        display: flex;
        height: 5.5rem;

        .lot-card-metrics {
          display: flex;
          flex-direction: column;
          width: 100%;

          .lot-card-data-row {
            align-items: center;
            display: flex;
            font-size: 1.125rem;
            height: 1.625rem;
            justify-content: space-between;
            padding-right: .375rem;

            &.oversold {
              .data-legend,
              .lot-metric {
                color: $warning-color;
              }
            }
          }

          .data-legend {
            display: flex;

            .legend-box {
              border-radius: 1.875px;
              height: .75rem;
              margin: 1.4375rem .5rem 0;
              width: .75rem;

              &.available-color {
                background: rgba(239, 239, 239, 1);
              }
            }
          }

          .lot-metric {
            font-family: $font-demi;
          }

          p {
            margin-bottom: .25rem;
          }
        }

        .barchart {
          margin: .3125rem;
          max-height: 100%;
          overflow: hidden;

          div {
            border: 1px solid $white;
            border-radius: 2px;
            height: 33%;
            width: 2rem;
          }

          .available-color {
            background: rgba(239, 239, 239, 1);
          }
        }
      }

      &.normal {
        .total-color {
          background: linear-gradient(0deg, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), #5acb58;
        }

        .prepaid-color {
          background: linear-gradient(0deg, rgba(255, 255, 255, .3), rgba(255, 255, 255, .3)), #5acb58;
        }
      }

      &.warning {
        .total-color {
          background: linear-gradient(0deg, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), #f3d25e;
        }

        .prepaid-color {
          background: linear-gradient(0deg, rgba(255, 255, 255, .3), rgba(255, 255, 255, .3)), #f3d25e;
        }
      }

      &.danger {
        .total-color {
          background: linear-gradient(0deg, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), #ff7373;
        }

        .prepaid-color {
          background: linear-gradient(0deg, rgba(255, 255, 255, .3), rgba(255, 255, 255, .3)), #ff7373;
        }
      }
    }
  }
}

.lots-map-container {
  height: calc(100vh - 4.5rem);
  margin-left: 8.5rem;
  width: calc(100% - 8.5rem);
}
