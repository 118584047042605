.text-input {
  border-bottom: .125rem solid var(--gray-600);
  padding-bottom: .5rem;

  &[readonly] {
    border-bottom: .125rem dashed var(--gray-300);
    color: var(--gray-600);
  }
}

.text-input-fieldset {
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.text-input-label-text {
  color: var(--gray-600);
  font-size: .75rem;
}

.text-input-label {
  svg {
    --icon-size: .5rem;

    fill: var(--gray-600);
    margin-left: .375rem;
    max-height: var(--icon-size);
    max-width: var(--icon-size);
    width: 100%;
  }
}

.text-input-label {
  display: grid;
  gap: .375rem;
}

.text-input-fieldset.focused {
  .text-input:not([readonly]) {
    border-bottom: .125rem solid var(--blue);
  }

  svg {
    fill: var(--blue);
  }

  .text-input-label-text {
    color: var(--blue);
  }
}
