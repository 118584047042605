@import '../../scss/imports';

#overview {
  padding-bottom: 2.5rem;
  
  .top-action-bar {
    padding: .75rem 0 1rem;

    @include max-screen($xs) {
      overflow-y: auto;

      .filter-bar {
        flex-wrap: nowrap;
        width: 100%;
      }
    }
  }

  .chart-card {
    overflow: hidden;

    &.list {
      min-height: 28.1875rem;
    }
  }

  .chart-section-compact {
    display: flex;
    flex-direction: column;
    height: 18.375rem;
    justify-content: space-between;
  }

  .chart-section-1,
  .chart-section-2,
  .chart-section-3,
  .context-container {
    display: grid;
    grid-gap: 1rem;
    width: 100%;
  }

  @media (min-width: $ipad) {
    .chart-section-1,
    .chart-section-3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 64rem) {
    .chart-section-1,
    .chart-section-2 {
      grid-template-columns: repeat(4, 1fr);
    }

    .chart-section-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.loading-spinner-container {
  align-items: center;
  display: grid;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.error-fallback {
  align-items: center;
  display: grid;
  height: 8.75rem;
  justify-content: center;
  text-align: center;

  .reset-chart-button {
    color: #66bb6a;
    font-family: $font-demi;
    text-transform: uppercase;
  }
}
