@import '../../scss/imports';

.cashier-card {
  .metric-list-column {
    height: 3.625rem;
  }

  .table__th,
  .table__td {
    &:nth-child(1) {
      max-width: 12rem;
    }

    &:nth-child(2) {
      max-width: 5rem;
    }

    &:nth-child(3) {
      max-width: 5rem;
    }

    &:nth-child(4) {
      max-width: 7rem;
    }

    &:nth-child(5) {
      max-width: 7rem;
    }

    &:nth-child(6) {
      max-width: 8rem;
    }

    &:nth-child(7) {
      max-width: 8rem;
      text-transform: none;
    }

    &:nth-child(8) {
      padding: .5rem;
    }
  }
}
