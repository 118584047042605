@import '../../scss/imports';

#exemptions-list-view {
  @media (max-width: $sm) {
    .search-input {
      display: none;
    }
  }
}

.exemptions-event-container {
  margin: 1rem;

  @media (min-width: $md) {
    margin: 1.5rem;
  }

  .exemptions-event-header {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 1rem 0 1rem 1rem;
    text-transform: capitalize;

    @media (min-width: $md) {
      margin: 0 1.5rem;
    }
  }

  .exemptions-data-header {
    display: flex;
    font-size: .875rem;
    justify-content: space-between;
    margin: 0 1rem;

    @media (min-width: $md) {
      margin: 0 1.5rem;

      p {
        margin-right: 1.25rem;
      }
    }
  }

  .exemptions-data-container {
    ul {
      background: $white;
      border-radius: .25rem;
      display: flex;
      font-size: 1.125rem;
      justify-content: space-between;
      margin: .5rem 0;
      padding: .5rem 1rem;

      @media (min-width: $md) {
        margin: .25rem 0;
        padding: .5rem 1.5rem;
      }

      &:first-of-type {
        font-weight: 700;
      }
      
      li {
        list-style: none;

        @media (min-width: $md) {
          &:nth-of-type(2) {
            margin-right: 1.25rem;
          }
        }
      }
    }
  }
}
