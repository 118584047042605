/* APP COLORS */
$black: #000;
$white: #fff;
$blue: #2196f3;
$blue-light: #deeffd;
$primary-color: $blue;
$secondary-color: #d8d8d8;
$tertiary-color: #e0e0e0;
$tertiary-text-color: $black;
$info-color: #0c07ef;
$info-light-color: rgba($info-color, .05);
$priority-color: #f11a1a;
$warning-color: #ff6363;
$warning-text-color: $white;
$disabled-color: #c3c3c3;
$inactive-color: rgba(#000, .36);
$text-color: #2d3843;
$secondary-text-color: rgba($black, .6);
$body-bg-color: #fafafa;
$toast-action-color: $blue;

/* TEXTURES */
$border-radius: .25rem;
$box-shadow: 0 .125rem .25rem rgba($black, .2);
$md-box-shadow: 0 .125rem .125rem 0 rgba(0, 0, 0, .14), 0 .0625rem .3125rem 0 rgba(0, 0, 0, .12), 0 .1875rem .0625rem -.125rem rgba(0, 0, 0, .2);


/* FONTS */
$font-path: '../assets/fonts';
$font-ultra-light: 'AvenirNextLTW01-UltraLi', Arial, sans-serif !default;
$font-light: 'Avenir Next W01 Light', Arial, sans-serif !default;
$font-regular: 'AvenirNextLTW01-Regular', Arial, sans-serif !default;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial, sans-serif !default;
$font-demi: 'Avenir Next LT W01 Demi', Arial, sans-serif !default;
$font-heavy: 'AvenirNextLTW01-Heavy', Arial, sans-serif !default;

$custom-typography-styles: (
  letter-spacing: normal,
);

/* SCREEN SIZES */
$xxs: 25rem;       // 400px
$xs: 30rem;        // 480px
$sm: 37.5rem;      // 600px
$smt: 50rem;       // 800px
$md: 60rem;        // 960px
$lg: 80rem;        // 1280px
$xl: 90rem;        // 1440px
$ipad: 48rem;      // 768px
$ipad-landscape: 64rem; // 1024px

$wrapper-width: 85rem;
$wrapper-padding: 1rem;
$wrapper-padding-sm: .75rem;

$ph-font-path: 'assets/fonts';
$ph-header-background-color: #fff;
$ph-header-action-button-color: $text-color;
