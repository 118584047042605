@import '../../scss/imports';

.table__container {
  --border: 1px solid #dedede;
  --padding-side: .875rem;

  font-family: var(--font-demi);
  margin: 0 auto;
  max-width: 100%;
  overflow: auto;
  width: 100%;
}

.table__button--body {
  height: 100%;
  overflow: hidden;
  width: 100%;

  svg,
  .material-icons {
    vertical-align: middle;
  }
}

.table__button--sort {
  align-items: center;
  display: grid;
  gap: .5rem;
  grid-template-columns: 1fr 1rem;
  text-align: inherit;

  .sort-icon {
    height: 1rem;
  }
}

.sort-icon {
  .sort-icon__triangle--bottom,
  .sort-icon__triangle--top {
    opacity: .25;
  }

  &.asc {
    .sort-icon__triangle--top {
      opacity: 1;
    }
  }

  &.desc {
    .sort-icon__triangle--bottom {
      opacity: 1;
    }
  }
}

.table__td--clickable,
.table__th,
.table__td {
  font-size: 1rem;
  text-align: start;
}

.table__td--clickable,
.table__th,
.table__td {
  &:first-child {
    margin-left: 3rem;
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 1rem;
  }
}

.table__td--clickable,
.table__td {
  padding: 0 .5rem;
}

.table__th {
  padding: .5rem;
}

.table__button--body,
.table__td {
  height: 3rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table__td,
.table__td--clickable {
  &.warning {
    color: var(--danger);
  }
}

.table__td {
  &:first-child {
    border-radius: .5rem 0 0 .5rem;
  }

  &:last-child {
    border-radius: 0 .5rem .5rem 0;
  }
}

.table__td,
.table__td--clickable {
  &:first-child {
    border-radius: .5rem 0 0 .5rem;

    .table__button--body {
      border-radius: .5rem 0 0 .5rem;
    }
  }

  &:last-child {
    border-radius: 0 .5rem .5rem 0;

    .table__button--body {
      border-radius: 0 .5rem .5rem 0;
    }
  }
}

.table__row--body:hover,
.table__row--body:focus-within {
  &.table__row--body-clickable {
    cursor: pointer;
  }

  .table__td,
  .table__td--clickable {
    background-color: rgba(0, 0, 0, .1);
    transition: background-color .15s ease-in-out;
  }
}

.table__th {
  background-color: var(--gray-100);
  position: sticky;
  top: 0;
}

.table__row--body {
  background-color: var(--white);
  border-radius: .5rem;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cashier,
  .last-transaction {
    max-width: 5rem;
  }

  .table__td--clickable,
  .table__td {
    background-color: var(--white);
  }

  &[data-status-indicator = 'danger'] {
    background-color: var(--danger-light);

    .table__td--clickable,
    .table__td {
      background-color: var(--danger-light);
    }
  }

  &[data-status-indicator = 'warning'] {
    background-color: var(--warning-light);

    .table__td--clickable,
    .table__td {
      background-color: var(--warning-light);
    }
  }

  &[data-status-indicator = 'info'] {
    background-color: var(--info-light);

    .table__td--clickable,
    .table__td {
      background-color: var(--info-light);
    }
  }
}

.table__table {
  border-collapse: separate;
  border-spacing: 0 .5rem;
  padding: 0 1.5rem;
  position: relative;
  width: 100%;

  .reader-battery,
  .iphone-battery {
    text-align: center;

    .highlight-cell {
      background: rgba(255, 232, 228, 1);
      border: 4px solid rgba(255, 232, 228, 1);
      border-radius: .1875rem;
      color: #ff5722;
    }
  }
}

@media(max-width: $sm) {
  .table__container {
    .table__table {
      border-spacing: 0 1rem;
      padding: 0 .75rem;

      .table__th {
        font-size: 1.125rem;
        font-weight: 700;
      }

      .table__row--body {
        box-shadow: 0 .375rem 1.625rem 0 rgba(130, 141, 168, .2);
      }
    }
  }
}
