@import '../../scss/imports';

.graph-bar-details {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr auto;

  .graph-bar-details-label {
    align-items: center;
    color: $text-color;
    display: inline-grid;
    font-family: $font-regular;
    font-size: 1.125rem;
    grid-column-gap: .375rem;
    grid-template-columns: 1rem auto;

    &.graph-bar-details-label-total {
      font-family: $font-demi;
      grid-template-columns: auto;
    }

    &.graph-bar-details-label-subsegment {
      padding-left: 1.375rem;
    }
  }

  .graph-bar-details-legend {
    border-radius: .1875rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
  }

  .graph-bar-details-value {
    color: $text-color;
    display: grid;
    font-family: $font-demi;
    font-size: 1.125rem;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    text-align: center;

    &.graph-bar-details-header {
      font-family: $font-regular;
      font-size: 1.125rem;
    }

    &.double-col {
      grid-template-columns: 1fr 1fr;
    }
  }

  .graph-bar-details-sub-value {
    font-family: $font-regular;
  }
}
