@import '../../scss/imports';

.cashier-details-container {
  padding: 0 1.5rem 1.5rem;

  .transaction__list {
    margin: 1.5rem 0;
    padding: 0;
  }

  .transaction__detail {
    text-transform: none;
  }

  .dl-header {
    font-family: $font-demi;
    font-size: 1.5rem;
    margin: 0;
  }

  .cashier-details-footer {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
  }
}
