.tooltip {
  display: inline-block;
  margin-left: .25rem;
  position: relative;

  .tooltip-button {
    --icon-color: rgba(0, 0, 0, .4);
    align-items: center;
    border: 1px solid var(--icon-color);
    border-radius: 50%;
    color: var(--icon-color);
    display: inline-flex;
    font-family: var(--font-demi);
    font-size: .5rem;
    height: .8rem;
    justify-content: center;
    width: .8rem;
  }

  .tooltip-text {
    background-color: var(--black);
    border-radius: .185rem;
    color: var(--white);
    font-size: .875rem;
    font-weight: var(--font-demi);
    left: 100%;
    margin: 0;
    padding: .5rem;
    position: absolute;
    text-align: center;
    top: calc(100% + .5rem);
    transform: scale(0) translateX(-50%);
    transform-origin: top left;
    transition: transform .1s ease-in-out;
    width: 15rem;

    &::before {
      --arrow-size: .375rem;
      border-bottom: var(--arrow-size) solid var(--black);
      border-left: var(--arrow-size) solid transparent;
      border-right: var(--arrow-size) solid transparent;
      bottom: 100%;
      content: '';
      height: 0;
      left: calc(50% - .75rem);
      position: absolute;
      width: 0;
    }
  }

  .tooltip-button[aria-expanded='true'] + .tooltip-text {
    transform: scale(1) translateX(-50%);
  }
}
