@import '~@react-md/button/dist/mixins';

.kebab-dropdown__container {
  position: relative;
}

.dropdown-button-list-text {
  font-family: var(--font-regular);
}

.kebab-button {
  --rmd-button-color: #616161;
  @include rmd-button-theme-update-var(icon-size, 2rem);
  @include rmd-button-theme-update-var(background-color, rgba(#616161, .1));
  max-height: 1.75rem;
  max-width: 1.75rem;

  .svg {
    size: .25rem;
  }
}
