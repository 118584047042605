@import '../../scss/imports';

.metric-list {
  height: 20.25rem;

  table {
    width: 100%;

    td {
      padding: .5rem .25rem;
    }

    tr {
      &.refunded {
        background-color: $info-light-color;
      }

      &.pending {
        background-color: var(--warning-light);
      }

      &.failed {
        background-color: var(--danger-light);
      }
    }

    &.cashiers-metric-table {
      td {
        padding: .375rem .25rem;
      }
    }
  }

  thead td {
    font-size: .75rem;
    padding: .25rem;
  }

  .metric-list-transactions {
    td {
      padding: 0 .25rem;
    }
  }

  .reader-battery-column {
    text-align: center;

    @media (min-width: $ipad-landscape) and (max-width: '71rem') {
      display: none;
    }
  } 

  .metric-list-column {
    .highlight-cell {
      background: rgba(255, 87, 34, .1);
      border-radius: .1875rem;
      color: #ff5722;
      height: 1.625rem;
      line-height: 1.625rem;
    }

    .metric-highlight-cell {
      padding-left: .1875rem;
    }

    &.status-icon {
      padding-left: 1rem;
    }
    
    &.clipped {
      max-width: 6rem;
    }

    span {
      display: block;
    }

    &.button-cell {
      max-width: 1.75rem;
      position: relative;
    }

    &.clipped {
      max-width: 6rem;
    }

    &.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.lot-name {
      max-width: 11rem;
    }

    &.total-revenue {
      max-width: 6rem;
    }

    &.spots-parked {
      max-width: 6rem;
    }

    &.cashier-name {
      max-width: 7rem;
      
      @media (max-width: '71rem') {
        max-width: 4rem;
      }
    }

    &.transaction-time {
      max-width: 6.375rem;
      padding-right: 1rem;

      @media (max-width: '71rem') {
        max-width: 4rem;
      }
    }

    &.cashier-lot {
      max-width: 6.5rem;
      padding-right: .375rem;

      .metric-list-cell-bottom {
        max-width: 90%;
      }
    }

    &.cashier-total {
      max-width: 4rem;
    }

    @media (min-width: $ipad-landscape) and (max-width: '71rem') {
      .reader-battery-column {
        display: none;
      }

      &.cashier-name {
        max-width: 3rem;
      }

      &.lot-name {
        max-width: 6rem;
      }
    }
  }

  .metric-list-cell-top {
    font-family: $font-demi;
    font-size: 1.125rem;
  }

  .metric-list-cell-bottom {
    font-size: .75rem;
    margin-top: .1875rem;
  }

  .metric-list-cell-top,
  .metric-list-cell-bottom {
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;

    &.no-cap {
      text-transform: none;
    }
  }
}

$timeout: 300ms;

.fade-enter {
  background-color: #fff;
  opacity: 0;
}

.fade-enter-active {
  background-color: rgba($primary-color, .1);
  opacity: 1;
  transition: all $timeout ease-in-out;
}

.fade-enter-done {
  background-color: #fff;
  transition: all $timeout ease-in;
}

.fade-exit {
  opacity: 1;
  transition: all $timeout ease-in-out;
}

.fade-exit-active {
  opacity: 0;
  transition: all $timeout ease-in-out;
}


@media (max-width: $sm) {
  .chart-card-action-container {
    background: rgba(0, 0, 0, .05);
  }

  .cashier-card {
    .metric-list {
      td:nth-of-type(1) {
        width: 2.125rem;
      }

      td:nth-of-type(2) {
        max-width: 5.625rem;
      }

      td:nth-of-type(3) {
        max-width: 4.375rem;
      }

      td:nth-of-type(4) {
        width: 3.25rem;
      }

      td:nth-of-type(5) {
        width: 2.125rem;
      }
    }
  }

  .lots-card {
    padding: .75rem;
    
    .metric-list {
      td:nth-of-type(1) {
        width: 2.125rem;
      }

      td:nth-of-type(2) {
        max-width: 6.125rem;
      }

      td:nth-of-type(3) {
        max-width: 4.875rem;
      }

    }
  }

  .transactions-card {
    padding: .75rem;
    
    .metric-list {
      td:nth-of-type(1) {
        width: 2.125rem;
      }

      td:nth-of-type(2) {
        max-width: 6rem;
      }
    }
  }
}
