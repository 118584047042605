@import '../../scss/imports';

.search-input {
  align-items: center;
  background-color: rgba(#828da8, .1);
  border: .0625rem solid #e0e0e0;
  border-radius: .75rem;
  box-sizing: border-box;
  display: flex;
  height: 2.25rem;
  margin: 0 1.625rem 1.25rem;
  padding-left: .6875rem;

  input {
    border: 0;
    height: 100%;
    padding-left: .875rem;
    width: 100%;

    &::placeholder {
      color: $inactive-color;
    }
  }

  .material-icons {
    color: $inactive-color;
  }

  @media(max-width: $sm) {
    border-color: transparent;
    margin: .375rem 1.375rem 0;
  }
}
