@import '../../scss/imports';

.chart-card {
  background: #fff;
  border: .125rem solid transparent;
  border-radius: .75rem;
  box-shadow: 0 .375rem 2rem rgba(#828da8, .2);
  display: flex;
  flex-direction: column;
  min-height: 15.625rem;
  padding: .875rem;
  position: relative;
  transition: border-color 200ms;

  .chart {
    align-items: center;
    display: flex;
    flex-grow: 1;
  }

  &.indicate-focus-state:hover {
    border-color: rgba(255, 87, 34, .4);
  }

  &.transactions-card {
    border: 0;
    padding: 1rem 0;

    .chart-card-label {
      margin-left: 1rem;
    }

    .chart-card-action-container {
      margin: 1rem auto auto;
      width: calc(100% - 2rem);
    }
  }

  .dialog-button {
    background: rgba(0, 0, 0, .05);
    box-shadow: none;
    width: 100%;
  }

  .chart-card-label {
    align-items: center;
    display: flex;

    svg {
      align-self: flex-start;
      margin: .125rem .5rem 0 .25rem;
    }

    h3 {
      font-family: $font-demi;
      font-size: 1.375rem;
      margin: 0 0 1.125rem;
    }
  }

  .chart-card-action-container {
    margin-top: 1rem;
    text-align: center;
    width: 100%;
  }

  &.compact {
    min-height: calc(50% - .5rem);
  }
}

.top-metric {
  flex: 0;
  margin-bottom: 1rem;
  position: relative;

  .top-metric-value {
    color: $text-color;
    font-family: $font-numbers;
    font-size: 2.5rem;
    margin: 0;
    padding-top: 1.25rem;
    text-shadow: none;
    transform: none;
    transition: all 500ms ease-in-out;

    .top-metric-prefix {
      display: inline-block;
      height: 3.125rem;
      vertical-align: middle;
    }
  }

  .top-metric-label {
    font-size: 1.125rem;
    margin: 0;
  }

  .top-metric-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.top-metric-compact {
  .top-metric-value {
    margin: 0;
    padding: 0;
  }

  .top-metric-label {
    margin-top: .25rem;
  }
}

.odometer {
  font-size: 2rem;

  &.odometer-auto-theme {
    font-family: $font-numbers;
  }
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  transition: transform 500ms;
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: transform 500ms;
}
