@import '../../scss/imports';

.chart-card {
  &.loading {
    align-content: space-around;
    display: grid;
    grid-row-gap: 1rem;

    &.metric {
      grid-template-rows: 2.5rem 1.25rem 50%;
    }

    &.compact {
      grid-template-rows: 2.5rem 1.25rem;
    }

    &.list {
      grid-template-rows: 1.6875rem repeat(5, 15%);
    }

    .loading-metric,
    .loading-title,
    .loading-content,
    .loading-list {
      @include loading-styles();
      border-radius: .3125rem;
      height: 100%;
    }

    &.transactions-card > .loading-metric,
    &.transactions-card > .loading-title,
    &.transactions-card > .loading-content,
    &.transactions-card > .loading-list {
      margin: 0 .75rem;
    }
  }
}
