@import '../../scss/imports';

#revenue-expanded-modal {
  .segmentation-form {
    display: grid;

    .segmentation-controls {
      @media (max-width: $sm) {
        font-size: .75rem;
      }
    }
  }

  h2 {
    font-family: $font-demi;
    font-size: 1.375rem;
    margin-top: 0;

    @media (max-width: $sm) {
      font-size: 1.125rem;
    }
  }

  .totals-header {
    background-color: $white;
    border-radius: .5rem;
    font-size: 1rem;
    margin: 1rem 1rem 0;
    padding: 0 .75rem;
    
    @media (min-width: $sm) {
      border-radius: .75rem;
      font-size: 1.375rem;
      margin: 1rem 1.5rem 0;
      padding: 1.5rem;
      
      dl {
        margin: 0;
      }
    }
  }
  
  .data-row {
    display: flex;
    font-family: $font-demi;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }

  .pricing-details-container {
    background: $white;
    border-radius: .5rem;
    margin: 1rem;
    padding: .75rem .75rem .25rem;

    i {
      margin-left: .25rem;
      position: relative;
      top: .25rem;
    }

    @media (min-width: $sm) {
      border-radius: .75rem;
      margin: 1.5rem;
      padding: 1.5rem 1.5rem .75rem;
    }

    .legend-name {
      dd {
        font-family: $font-demi;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      margin: .75rem 0;
      text-transform: capitalize;
      transition: max-height .2s ease-in, opacity .4s ease-in-out;

      .graph-bar-details-legend {
        border-radius: .1875rem;
        display: inline-block;
        height: 1rem;
        margin-right: .375rem;
        width: 1rem;
      }

      dt {
        align-items: center;
        display: flex;
      }
    }

    .legend-hidden {
      margin: 0;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
    }
  
    .legend-expanded {
      max-height: 1.25rem;
      opacity: 1;
    }

    .sub-legend {
      margin-left: 1.375rem;
    }
  }
}
