@import '../../scss/imports';

.feature-in-development-modal-content {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: .25rem;
  box-shadow: 0 .125rem .5rem rgba(0, 0, 0, .1);
  padding: 3rem;

  @include max-screen($xs) {
    padding: 1rem;
  }

  @include landscape() {
    @include max-screen($md) {
      padding: .55rem;

      p {
        margin: 0 auto;
      }
    }
  }

  p {
    color: #9d9d9d;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5625rem;
    margin: 1rem auto;
    max-width: 24.375rem;
    text-overflow: wrap;
  }
}

#feature-in-development-dialog {
  background: #f6f6f6;
  border-radius: .75rem;
  padding: $wrapper-padding;
  position: relative;
  text-align: center;

  @include max-screen($xs) {
    padding: $wrapper-padding-sm;
  }

  .modal-headline {
    color: $text-color;
    font-family: $font-demi;
    font-size: 2rem;
    margin: 0;
    min-height: 2.5rem;
    text-align: center;

    @include max-screen($xs) {
      font-size: 1.5rem;
      min-height: auto;
    }
  }

  .modal-subheadline {
    @include landscape() {
      @include max-screen($md) {
        margin: .75rem auto;
        max-width: 70%;
      }
    }
  }

  .modal-close-button {
    position: absolute;
    right: .75rem;
    top: .75rem;

    @include max-screen($xs) {
      right: .25rem;
      top: .25rem;
    }
  }
}

#feature-in-development-dialog-container {
  --rmd-dialog-horizontal-margin: #{$wrapper-padding-sm};
}
