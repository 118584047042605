$fonts: (
  font-ultra-light: 'AvenirNextLTW01-UltraLi',
  font-light: 'Avenir Next W01 Light',
  font-regular: 'AvenirNextLTW01-Regular',
  font-numbers: 'Avenir Next Cyr W00 Regular',
  font-demi: 'Avenir Next LT W01 Demi',
  font-heavy: 'AvenirNextLTW01-Heavy',
);

$colors: (
  'blue': $blue,
  'blue-light': #deeffd,
  'white': $white,
  'black': $black,
  'text-color': $text-color,
  'red': #e53935,
  'danger': #e53935,
  'danger-light': #ffe8e4,
  'success': #90dc13,
  'warning': #ffdc00,
  'warning-light': #fffae7,
  'info': $info-color,
  'info-light': rgba($info-color, .05)
);

@mixin shade-variant($color-name, $color) {
  --#{$color-name}-100: #{$color};
  @for $i from 2 through 9 {
    --#{$color-name}-#{$i * 100}: #{mix($color, #000, 100 - $i * 10)};
  }
}

:root {
  @each $name, $value in $fonts {
    --#{$name}: '#{$value}', Arial, sans-serif;
  }
  @each $name, $value in $colors {
    --#{$name}: #{$value};
  }
  @include shade-variant('gray', #f6f6f6);
}
