@import '../../scss/imports';

.transactions-card {
  .table__td--clickable,
  .table__th {
    font-family: var(--font-regular);
    font-size: .875rem;

    &:nth-child(1) {
      max-width: 4rem;
    }

    &:nth-child(2) {
      max-width: 3.5rem;
    }

    &:nth-child(3) {
      max-width: 7rem;
    }

    &:nth-child(4) {
      max-width: 7rem;
    }

    &:nth-child(5) {
      max-width: 5rem;
    }

    &:nth-child(6) {
      max-width: 6rem;
    }

    &:nth-child(7) {
      max-width: 6rem;
    }

    &:nth-child(8) {
      overflow: visible;
    }
  }

  .table__th {
    &:nth-child(1) {
      padding-left: .375rem;
    }
  }
}

.no-results-container {
  margin: 0 auto;
}
