@import '../../../scss/imports';

.refunds-container {
  color: #000;
  font-size: .875rem;
  padding: 1.5rem 0;

  @media (max-width: $sm) {
    background: $white;
    border-radius: .75rem;
    box-shadow: 0 .375rem 2rem 0 rgba(130, 141, 168, .2);
    color: $text-color;
    grid-row-gap: .0625rem;
    margin: 1.375rem 1rem 1.125rem;
    padding: .25rem 0;
  }

  hr.divider {
    margin: 0 1.5rem 2.25rem;

    @media (max-width: $sm) {
      display: none;
    }
  }

  .refund-actions,
  .refund-history {
    dl {
      display: grid;
      grid-row-gap: .375rem;
      grid-template-columns: 1fr 1fr;
      margin: 0;

      @media (max-width: $sm) {
        grid-row-gap: 0;
        
        i,
        svg {
          display: none;
        }
      }
    }

    dt,
    dd {
      align-items: center;
      margin: 0;
    }

    dt {
      padding: .75rem 0 .75rem .75rem;
    }

    dd {
      padding: .75rem .75rem .75rem 0;
    }
  }

  .refund-actions {
    .select-input {
      width: 60%;

      @media (max-width: $sm) {
        overflow: hidden;
        padding-right: 1.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    dl {
      padding: 0 1.5rem;

      @media (max-width: $sm) {
        padding: 0;
      }
    }

    dt,
    dd {
      background-color: #fff;
    }

    dt {
      border-radius: $border-radius 0 0 $border-radius;
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: auto 1fr;

      @media (max-width: $sm) {
        border-top-left-radius: .75rem;
      }
      
      .rmd-icon {
        font-size: 1rem;
      }
    }

    dd {
      border-radius: 0 $border-radius $border-radius 0;

      @media (max-width: $sm) {
        border-top-right-radius: .75rem;
      }
    }
  }

  .save-reason-action-container {
    align-items: center;
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: auto auto;
    justify-content: center;
    padding: 2.25rem 1.5rem;
    
    @media (max-width: $sm) {
      grid-column-gap: 1rem;
      grid-template-columns: calc(50% - 1rem) 1fr;
      justify-content: space-between;
      padding: 1.5rem .75rem .5rem;
    }
  }

  .refund-history {
    background-color: #f1f1f1;
    border-radius: .375rem;
    margin: 0 1.5rem;
    
    @media (max-width: $sm) {
      background: $white;
      border-radius: .75rem;
      margin: 0;
    }

    dl {
      padding: .375rem .75rem;

      @media (max-width: $sm) {
        padding: .75rem;
      }
    }

    dd,
    dt {
      padding: .375rem 0;
      @media (max-width: $sm) {
        background: #f9f9f9;
        padding: 1rem;
      }
    }

    
    
    dd {
      text-align: right;

      @media (max-width: $sm) {
        &:first-of-type {
          border-top-right-radius: .5rem;
        }
    
        &:last-of-type {
          border-bottom-right-radius: .5rem;
        }
      }
    }

    @media (max-width: $sm) {
      dt:first-of-type {
        border-top-left-radius: .5rem;
      }
  
      dt:last-of-type {
        border-bottom-left-radius: .5rem;
      }
    }
  }

  .error-container {
    text-align: center;
  }

  .error-message {
    color: $priority-color;
    display: block;
    padding: 0 1.5rem;
  }
}
